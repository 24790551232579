<template>
  <v-container class="mt-5 mb-15 px-10" fluid>
    <PageHeader>
      <template #header>
        <img
          src="@/assets/plugins/typeform_plugin.png"
          alt="typeform logo"
          height="32"
        >
        {{ installed ? 'Update ' : 'Install' }} Typeform Plugin
      </template>
      <template #subheader>
        The Typeform Plugin allows for processing form submissions coming from their forms.<br>
        Fill out the fields below in order to interact with the Typeform plugin.
      </template>
    </PageHeader>

    <div class="mt-10">
      <validation-observer
        ref="inputRef"
      >
        <div class="mb-3">
          To obtain the Personal Token value below, you must login to your
          <a href="https://admin.typeform.com/login">typeform.com</a> account. Go to the "Your Settings" page,
          and then to the "Personal tokens" page. Generate a new token with an appropriate name and make sure to give
          access to All scopes. Then copy and paste the generated token in the corresponding field below.
        </div>
        <div>
          <CustomTextInput
            v-model="token"
            header="Personal Token"
            type="password"
            required
          />
        </div>

        <hr v-if="installed">
        <v-row v-if="installed" class="mt-3">
          <v-col cols="12" md="6">
            <CustomDropdown
              v-model="workspaceId"
              header="Workspace"
              placeholder="Select a Workspace"
              :items="workspaces"
              required
              item-value="id"
              item-text="name"
            />
          </v-col>
        </v-row>
      </validation-observer>

      <v-row class="mt-12">
        <v-col cols="12" class="d-flex justify-end">
          <v-btn
            v-if="installed"
            class="custom-button custom-button--red-text px-8 mr-4"
            height="34px"
            depressed
            @click="deactivateDialog = true;"
          >
            Deactivate
          </v-btn>

          <v-btn
            class="custom-button custom-button--blue px-13"
            height="34px"
            depressed
            :block="$vuetify.breakpoint.xsOnly"
            @click="trigger"
          >
            {{ plugin ? 'Update' : 'Install' }}
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <DeactivateConfirmDialog
      v-if="deactivateDialog"
      :id="id"
      :plugin="plugin"
      @dismiss="deactivateDialog = false;"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import DeactivateConfirmDialog from "./components/DeactivateConfirmDialog";

export default {
  name: "AddPluginTypeform",
  metaInfo: {
    title: 'Install Typeform'
  },
  components: {
    CustomDropdown,
    CustomTextInput,
    DeactivateConfirmDialog,
    PageHeader,
  },
  props: {
    id: {
      default: () => null,
      type: [Number, String],
    },
    installed: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    deactivateDialog: false,
    token: null,
    workspaceId: null,
    plugin: null,
    workspaces: [],
  }),
  async created() {
    if (this.installed) {
      await this.loadWorkspaces();
      await this.loadAccountPlugin();
    }
  },
  methods: {
    async loadWorkspaces() {
      const response = await this.$rest.plugins.typeform.getWorkspaces();
      if (!response.data) {
        return;
      }

      this.workspaces = response.data.items.map(workspace => ({
        id: workspace.id,
        name: workspace.name,
      }));
    },
    async loadAccountPlugin() {
      const response = await this.$rest.plugins.get_resource(this.id, {
        includeCredentials: true,
      });
      if (!response.data) {
        return;
      }

      this.plugin = {...response.data};
      this.token = this.plugin?.pluginConfiguration?.credentials?.token;
      this.workspaceId = this.plugin?.pluginConfiguration?.workspaceId;
    },
    trigger() {
      if (this.plugin) {
        return this.updatePlugin();
      } else {
        return this.installPlugin();
      }
    },
    async updatePlugin() {
      const pluginConfiguration = {
        credentials: {
          token: this.token,
        },
        workspaceId: this.workspaceId,
      };

      const response = await this.$rest.plugins.put_resource(this.id, {
        ...this.plugin,
        plugin: this.plugin.plugin.id,
        pluginConfiguration
      });
      if (!response.data) {
        return this.$store.commit('snackbar/showMessage', {
          content: 'Failed to update plugin',
          color: 'error',
        });
      }

      this.$store.commit('snackbar/showMessage', {
        content: 'Plugin updated successfully',
        color: 'info',
      });
      setTimeout(() => {
        this.$router.push({
          name: "Plugins",
        })
      }, 500);
    },
    async installPlugin() {
      const pluginConfiguration = {
        credentials: {
          token: this.token,
        },
      };

      const response = await this.$rest.plugins.post_resource({
        plugin: +this.id,
        isActive: true,
        pluginConfiguration,
      });
      if (!response.data) {
        return this.$store.commit('snackbar/showMessage', {
          content: 'Failed to install plugin',
          color: 'error',
        });
      }

      const selected_account = this.$store.getters["user/account"];
      this.$store.commit("user/update_selected_account", {
        ...selected_account,
        activePlugins: [...selected_account.activePlugins, response.data],
      });

      this.$store.commit('snackbar/showMessage', {
        content: 'Installed plugin successfully',
        color: 'info',
      });
      setTimeout(() => {
        this.$router.push({
          name: "AddPluginTypeform",
          params: { id: response.data?.id },
          query: { installed: true }
        });
      }, 500);
    },
  },
}
</script>
